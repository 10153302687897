import React, { useState } from 'react';
import PropTypes from 'prop-types';

function transformData(data) {
    let transformed = [];
    for (let i = 0; i < data.length; i++) {
      let trendMap = {};
      if (data[i].chartConfig && Array.isArray(data[i].chartConfig.trends) && data[i].chartConfig.trends.length > 0) {
        for (let j = 0; j < data[i].chartConfig.trends.length; j++) {
            let obj = data[i].chartConfig.trends[j];
            if (!trendMap[obj.trend]) {
            trendMap[obj.trend] = {
                arr_index: i,
                trend: obj.trend,
                patients: []
            };
            }
            trendMap[obj.trend].patients.push(obj.patient_id);
        }
        transformed.push(...Object.values(trendMap));
        }
    }
  
    return transformed;
  }

const DropdownTrends = ({ options, trends, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const mappedTrends = transformData(trends);
  const handleSelectChange = (event) => {
    const option = event.target.value
    const array = option.split(',');
    setSelectedOption(option);
    onSelect(array);
  };
  const users = options[0]
  let filteredArray = Object.keys(users)
    .filter(key => key !== "month" && key !== "week" && key !== "day")
    .map(key => ({ label: key, value: users[key] }));
  filteredArray.unshift({label: "Alla", value: 0})
  const uniqueTrendsWithPatients = Array.from(
    mappedTrends.reduce((map, item) => {
      if (!map.has(item.trend)) {
        map.set(item.trend, { trend: item.trend, patients: [] });
      }
      map.get(item.trend).patients.push(...item.patients);
      return map;
    }, new Map()).values()
  );
  return (
    <div>
      <select value={selectedOption} onChange={handleSelectChange}>
      {uniqueTrendsWithPatients.map((item, index) => (
        <option key={item.trend + index} value={item.patients}>
          {item.trend}
        </option>
      ))}
      </select>
    </div>
  );
};
DropdownTrends.propTypes = {
  options: PropTypes.any.isRequired,
  onSelect: PropTypes.any.isRequired,
  trends: PropTypes.any.isRequired,
};
export default DropdownTrends;
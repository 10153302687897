import React from 'react';
import '../App.css';
import StatBox from './statsbox';

const Statistics = () => {
  return (
    <div className="dashboard-stats">
        <StatBox title="Antal brukare med avvikelse" value="1000" description="Räknat över hela perioden" />
        <StatBox title="Avvikelse per helg" value="500" description="Räknat över hela perioden" />
        <StatBox title="Avvikelse per månad" value="500" description="Räknat över hela perioden" />
        <StatBox title="Totalt antal brukare" value="5000" description="Räknat över hela perioden" />
        <hr/>
    </div>
  );
};

export default Statistics;

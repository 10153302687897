import React, { useState} from 'react';
import PropTypes from 'prop-types';


const DropdownGraphs = ({ options }) => {
  const [selectedOption, setSelectedOption] = useState('');
  

  const handleSelectChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const selectedValue = selectedOption.innerHTML;
    const anchorLink = '#' + selectedValue.toLowerCase().replace(/\s/g, '-');
    setSelectedOption(selectedValue);
    const anchorElement = document.querySelector(anchorLink);
    if (anchorElement) {
      const anchorPosition = anchorElement.getBoundingClientRect().top + window.pageYOffset;
      const scrollPosition = anchorPosition - (window.innerHeight * 0.2);

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth' 
      });
    }
  };
  let graphs = []
  for(let i = 0;  i<options.length; i++){
    graphs.push(options[i].chartConfig.title)
  }
  
  return (
    <div>
      <select value={selectedOption} onChange={handleSelectChange}>
        <option value="">Select an option</option>
        {graphs.map((title, index) => (
          <option key={index} value={title}>
            {title}
          </option>
        ))}
      </select>
    </div>
  );
};
DropdownGraphs.propTypes = {
  options: PropTypes.any.isRequired,
  onSelect: PropTypes.any.isRequired
};
export default DropdownGraphs;
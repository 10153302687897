// eslint-disable-next-line no-unused-vars
function filterDataByPatientID(data, categoryKeys) {
    if (categoryKeys.includes("Alla")) {
        return data;
    }
    return data.map(entry => {
        const filteredEntry = { month: entry.month };
        categoryKeys.forEach(key => {
            if (entry[key] !== undefined) {
                filteredEntry[key] = entry[key];
            }
        });
        return filteredEntry;
    });
}

function filterNestedArrayByPatientID(nestedArray, categoryKeys) {
    const filteredNestedArray = [];
    for (let i = 0; i < nestedArray.length; i++) {
        const filteredData = filterDataByPatientID(nestedArray[i].chartData, categoryKeys);
        filteredNestedArray.push({ chartData: filteredData, chartConfig: nestedArray[i].chartConfig });
    }
    return filteredNestedArray;
}

export default filterNestedArrayByPatientID;


import PropTypes from 'prop-types';

function ErrorPopup({errorMessage, onClose}) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>An error occurred</h2>
        <p>{errorMessage}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}
ErrorPopup.propTypes = {
    errorMessage: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ErrorPopup;
import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import DropdownUsers from './dropdownUsers';
import DropdownGraphs from './dropdownGraphs';
import DropdownTrends from './dropdownTrends';
import PropTypes from 'prop-types';

const FilterSection = ({ startDate, endDate, handleStartDateChange, handleEndDateChange, data, handleUserFilterChange, handleTrendFilterChange }) => {
  const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        function handleScroll() {
            const scrollPosition = window.scrollY;
            const element = document.querySelector('.follow-scroll');
            const elementHeight = element.offsetHeight;
            let windowHeight = window.windowHeight;
            if (scrollPosition === 0 || scrollPosition > element.offsetTop && scrollPosition < (element.offsetTop + elementHeight - windowHeight)) {
                setIsFixed(false);
            } else {
                setIsFixed(true);
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isFixed]);
  return (
    <div className={`follow-scroll ${isFixed ? 'fixed' : ''}`}>
      <ul>
        <li>Trender<br/>
        {data && <DropdownTrends options={data[0]?.chartData} trends={data} onSelect={handleTrendFilterChange}/> }
        </li>
        <li>Periodisering<br/>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Från datum"
            minDate={new Date(2022, 0, 1)}
            maxDate={new Date(2022, 11, 31)}
          />
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            placeholderText="Till datum"
            minDate={new Date(2022, 0, 1)}
            maxDate={new Date(2022, 11, 31)}
          />
        </li>
        <li>Välj brukare
          {data && <DropdownUsers options={data[0]?.chartData} onSelect={handleUserFilterChange} /> }
        </li>
        <li>Välj graf
          {data && <DropdownGraphs options={data} onSelect={handleUserFilterChange}/> }
        </li>
          
      </ul>
    </div>
    
  );
};

FilterSection.propTypes = {
    startDate: PropTypes.any.isRequired,
    endDate: PropTypes.any.isRequired,
    handleStartDateChange: PropTypes.any.isRequired,
    handleEndDateChange: PropTypes.any.isRequired,
    data: PropTypes.any.isRequired,
    handleUserFilterChange: PropTypes.any.isRequired,
    handleTrendFilterChange: PropTypes.any.isRequired,
  };

export default FilterSection;

function sumStackedArrays (data) {
    const combinedData = [];

    data.forEach((monthData) => {
      const combinedMonth = { month: monthData.month };
      Object.keys(monthData).forEach(key => {
        if (key !== 'month' && key !== 'week'&& key !== 'day') {
          monthData[key].forEach((value, i) => {
            if (!combinedMonth[`value${i + 1}`]) {
              combinedMonth[`value${i + 1}`] = value;
            } else {
              combinedMonth[`value${i + 1}`] += value;
            }
          });
        }
      });
      combinedData.push(combinedMonth);
    });
    return combinedData
}

export default sumStackedArrays;
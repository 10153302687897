import React, { useState } from 'react';
import PropTypes from 'prop-types';


const DropdownUsers = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState('');
  

  const handleSelectChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const selectedValue = selectedOption.innerHTML;
    const option = event.target.value
    setSelectedOption(option);
    onSelect(selectedValue);
  };
  const users = options[0]
  let filteredArray = Object.keys(users)
    .filter(key => key !== "month" && key !== "week" && key !== "day")
    .map(key => ({ label: key, value: users[key] }));

  filteredArray.unshift({label: "Alla", value: 0})
  return (
    <div>
      <select value={selectedOption} onChange={handleSelectChange}>
        {filteredArray.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
DropdownUsers.propTypes = {
  options: PropTypes.any.isRequired,
  onSelect: PropTypes.any.isRequired,
};
export default DropdownUsers;
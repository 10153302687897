import React from 'react';
import PropTypes from 'prop-types';

const DashboardHeader = ({ handleFileChange }) => {
  const handleGoBack = () => {
    window.location.href = "/";
  };
  return (
    <div className="dashboard-header">
      <h1>GraphMVP</h1>
      <input type="file" accept=".json" onChange={handleFileChange} className="dashboard-file-input" />
      <button onClick={handleGoBack}>Gå tillbaka</button>
    </div>
  );
};
DashboardHeader.propTypes = {
    handleFileChange: PropTypes.any.isRequired,
  };
export default DashboardHeader;

import React, {useEffect, useState} from 'react';
import '../App.css';
import PropTypes from 'prop-types';

const StatBox = ({ title, value, description }) => {
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    const targetValue = Number(value);
    const duration = 2000;
    const increment = Math.ceil(targetValue / (duration / 30)); 
    const updateDisplayValue = () => {
      setDisplayValue((prevValue) => {
        const newValue = prevValue + increment;
        return newValue >= targetValue ? targetValue : newValue;
      });
    };
    const interval = setInterval(updateDisplayValue, 20);
    return () => clearInterval(interval);
  }, [value]); 
  return (
    <div className="stat-box">
      <h2 className="title">{title}</h2>
      <div className="value">{displayValue}<span>{"st"}</span></div>
      <div className="description">{description}</div>
    </div>
  );
};

StatBox.propTypes = {
  title: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired
};

export default StatBox;

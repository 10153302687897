function filterByDate(monthsData, startDate, endDate) {
    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();
    let filteredMonths = monthsData.filter(monthObj => {
        const monthIndex = parseInt(monthObj.month.split(' ')[1]) - 1;
        return monthIndex >= startMonth && monthIndex <= endMonth;
    });
    return filteredMonths;
}

function filterNestedArrayByDate(nestedArray, startDate, endDate) {
    const filteredNestedArray = [];
    for (let i = 0; i < nestedArray.length; i++) {
        const filteredData = filterByDate(nestedArray[i].chartData, startDate, endDate);
        filteredNestedArray.push({chartData: filteredData, chartConfig: nestedArray[i].chartConfig});
    }
    return filteredNestedArray;
}

export default filterNestedArrayByDate
import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Statistics from './components/statistics';
import FilterSection from './components/filterSection';
import filterNestedArrayByCategory from './utils/filterUser';
import filterNestedArrayByDate from './utils/filterPeriod';
import RenderChart from './components/renderChart';
import DashboardHeader from './components/dashboardHeader';
import { RecoilRoot, useRecoilState } from 'recoil';
import dataState from './atom/dataStateAtom';
import cDataState from './atom/cDataStateAtom';
import startDateState from './atom/startDateStateAtom';
import endDateState from './atom/endDateStateAtom';
import GraphPage from './pages/graphPage';
import WizardPage from './pages/wizardPage';

const Root = () => {
  return (
    <RecoilRoot>
      <Router>
        <Routes>
          <Route exact path="/" element={<WizardPage />}/>
          <Route path="/dashboard" element={<GraphPage/>}/>
          <Route path="/app" element={<App/>}/>
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
      </Router>
    </RecoilRoot>
  );
};

const App = () => {
  const [data, setData] = useRecoilState(dataState);
  const [cData, setCData] = useRecoilState(cDataState);
  const [startDate, setStartDate] = useRecoilState(startDateState);
  const [endDate, setEndDate] = useRecoilState(endDateState);

  const handleStartDateChange = date => {
    setStartDate(date);
    let filterdData = filterNestedArrayByDate(data[0].chartData, startDate, endDate)
    setCData(filterdData)
  };

  const handleEndDateChange = date => {
    setEndDate(date);
    let filterdData = filterNestedArrayByDate(data[0].chartData, startDate, endDate)
    setCData(filterdData)
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const jsonData = JSON.parse(event.target.result);
          setData(jsonData);
          setCData(jsonData)
        } catch (error) {
          console.error('Error parsing JSON file:', error);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleUserFilterChange = (selectedValue) => {
    let filterdData = filterNestedArrayByCategory(data, selectedValue);
    setCData(filterdData)
  };

  useEffect(() => {
  }, []);

  return (
    <div className="dashboard-container">
      <DashboardHeader
        handleFileChange={handleFileChange}
      />
      <Statistics />
      <FilterSection
        startDate={startDate}
        endDate={endDate}
        data={data}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleUserFilterChange={handleUserFilterChange}
      />
      <div className='dashboard'>
        {cData && cData.map((item, index) => (
          (item.chartData && item.chartConfig) ? (
            <RenderChart key={index} chartKey={index} cData={item.chartData} chartConfig={item.chartConfig} />
          ) : null
        ))}
      </div>
    </div>
  );
};

export default Root;

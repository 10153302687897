function filterDataByCategory(data, categoryKey) {
    if(categoryKey === "Alla"){
        return data
    }
    return data.map(entry => ({
        month: entry.month,
        [categoryKey]: entry[categoryKey]
    }));
}


function filterNestedArrayByCategory(nestedArray, categoryKey) {
    const filteredNestedArray = [];
    for (let i = 0; i < nestedArray.length; i++) {
        const filteredData = filterDataByCategory(nestedArray[i].chartData, categoryKey);
        filteredNestedArray.push({chartData: filteredData, chartConfig: nestedArray[i].chartConfig});

    }
    return filteredNestedArray;
}

export default filterNestedArrayByCategory;

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Label,
    Legend,
    LineChart,
    Line,
    ResponsiveContainer,
    ReferenceArea,
    CartesianGrid,
  } from 'recharts';
  import PropTypes from 'prop-types';
  import sumStackedArrays from '../utils/sumStackedBars';

/* eslint-disable */
const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    const sortedPayload = payload.slice().sort((a, b) => b.value - a.value);
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label+1}`}</p>
        {sortedPayload.map((item, index) => (
          <p key={index} className="label">{`${item.dataKey}: ${item.value % 1 !== 0 ? item.value.toFixed(2) : item.value}`}</p>
        ))}
      </div>
    );
  }
  return null;
};

const StackCustomTooltip = ({ active, payload, label, nameMapping }) => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }
  const names = nameMapping.slice(0).reverse()
  const stackData = payload[0].payload;
  const tooltipContent = payload.slice(0).reverse().map((item, index) => {
    const name = names[index];
    const value = item.value;
    return (
      <div key={index}>
        <strong>{name}:</strong> {value}
      </div>
    );
  });
  return (
    <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
      <p className="label">{`${label+1}`}</p>
      {tooltipContent}
    </div>
  );
};

const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={5} y={-3} dy={16} textAnchor="end" fill="#666" >{`${payload.index + 1}`}</text>
      <line x1={0} y1={0} x2={0} y2={0} stroke="#666" strokeWidth={2} />
      {/* Adding border-radius to the bottom side */}
     
    </g>
  );
};
  const renderStackedBarChart = (data, config) => {
    
    const combinedData = sumStackedArrays(data)
    const max = combinedData.reduce((acc, month) => {
      const sum = Object.values(month)
        .filter(value => typeof value === 'number')
        .reduce((sum, value) => sum + value, 0);
      return Math.max(acc, sum);
    }, 0);
    const days = ["stack_names", "stack_names", "Replace with stack_names", "tors", "fre","lör","sön"];
    return (
      <>
      <h3 id={config.title.toLowerCase().replace(/\s/g, '-')}>{config.title}</h3>
      <ResponsiveContainer width={"100%"} height={300} padding={10}>
      <BarChart data={combinedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis tick={<CustomXAxisTick />}>
        <Label value={config.xAxisLabel} position="insideBottom" offset={-5}/>
        </XAxis>
        <YAxis>
        <Label value={config.yAxisLabel} angle={-90} position="insideLeft" />
        </YAxis>
        <Tooltip content={<StackCustomTooltip nameMapping={config?.stack_names ? config?.stack_names : days } />} />
        {Object.keys(combinedData[0]).map((key, index) => {
        if (key !== 'month' && key !== 'week' && key !== 'day') {
          return (
            <Bar key={index} dataKey={key} stackId="a" fill={config.stackColors ? config.stackColors[index] : `#${Math.floor(Math.random()*16777215).toString(16)}`} />
          );
        }
        return null;
      })}
      </BarChart>
      </ResponsiveContainer>
      </>
    );
  };
  const trendChecker = (trends, props) => {
    for(let i = 0; i<trends.length; i++){
      if(trends[i].index == props.index && trends[i].patient_id in props.payload){
        return true
      }
    }
    return false
  }
  const renderLineChart = (data, config, key) => {
    const trends = config.trends;
    
    const CustomDot = (props) => {
      const { cx, cy, payload } = props;

      const trendMatch = trendChecker(trends, props)
      if (payload.value === 0 || !cy) {
          return null; 
      }
        return (
          <circle cx={cx} cy={cy} r={trendMatch ? 10 : 4} fill={trendMatch ? "red" : "blue"} />
      );
      
  };
    return (
      <>
      <h3 id={config.title.toLowerCase().replace(/\s/g, '-')}>{config.title}</h3>
      <ResponsiveContainer width={"100%"} height={300} >
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<CustomTooltip />} />
        <XAxis tick={<CustomXAxisTick />}>
        <Label value={config.xAxisLabel} position="insideBottom" offset={-5}/>
        </XAxis>
        <YAxis>
         <Label value={config.yAxisLabel} angle={-90} position="insideLeft"/>
        </YAxis>
        {Object.keys(data[0])
          .filter((key) => key !== 'month') 
          .filter((key) => key !== 'week')
          .filter((key) => key !== 'day')
          .map((category) => (
            <Line
              key={category}
              type="monotone"
              dataKey={category}
              stroke={config.categoryColors[category] || `#1f77b4`} 
              connectNulls={true}
              dot={<CustomDot/>}
            />
          ))}
          {config.hasOwnProperty('referenceArea') ? (
          <>
            <ReferenceArea y1={null} y2={config.referenceArea.max_1} fill={config.referenceArea.color_1} fillOpacity={config.referenceArea.alpha} />
            <ReferenceArea y1={config.referenceArea.min_2} y2={null} fill={config.referenceArea.color_2} fillOpacity={config.referenceArea.alpha} />
          </>
          )
          : (null)}
      </LineChart>
      </ResponsiveContainer>
      </>
    );
};

const renderStackedLineChart = (data, config, key) => {
  const transformData = (data) => {
    return data.map((item) => {
      const newData = { month: item.month };
      Object.keys(item).forEach((key) => {
        if (Array.isArray(item[key])) {
          item[key].forEach((value, index) => {
            newData[`${key}_${index + 1}`] = value;
          });
        } else {
          newData[key] = item[key];
        }
      });
      return newData;
    });
  };
  const transformedData = transformData(data);
  
  return (
    <>
    <h3 id={config.title.toLowerCase().replace(/\s/g, '-')}>{config.title}</h3>
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<CustomTooltip />} />
        <XAxis dataKey="month" tick={<CustomXAxisTick />}>
          <Label value={config.xAxisLabel} position="insideBottom" offset={-5} />
        </XAxis>
        <YAxis>
          <Label value={config.yAxisLabel} angle={-90} position="insideLeft" />
        </YAxis>
        {Object.keys(transformedData[0])
          .filter((key) => key !== 'month')
          .map((category) => (
            <Line
              key={category}
              type="monotone"
              dataKey={category}
              stroke={config.categoryColors[category] || `#${Math.floor(Math.random() * 16777215).toString(16)}`}
            />
          ))}
        {config.referenceArea && (
          <>
            <ReferenceArea
              y1={null}
              y2={config.referenceArea.max_1}
              fill={config.referenceArea.color_1}
              fillOpacity={config.referenceArea.alpha}
            />
            <ReferenceArea
              y1={config.referenceArea.min_2}
              y2={null}
              fill={config.referenceArea.color_2}
              fillOpacity={config.referenceArea.alpha}
            />
          </>
        )}
      </LineChart>
    </ResponsiveContainer>
  </>
  );
};
  const renderBarChart = (data, config) => (
    <ResponsiveContainer width={"100%"} height={300} >
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" label={{ value: config.xAxisLabel, position: 'insideBottom' }} />
      <YAxis label={{ value: config.yAxisLabel, angle: -90, position: 'insideLeft' }} />
      <Tooltip />
      {config.legend && <Legend />}
      <Bar dataKey="value" fill={config.barColor} />
    </BarChart>
    </ResponsiveContainer>
  );

  const RenderChart = ({ chartKey, cData, chartConfig }) => {
    if (!cData) return null;

    switch (chartConfig.chartType) {
      case 'line':
        return renderLineChart(cData, chartConfig, chartKey)
      case 'lineStack':
        return renderStackedLineChart(cData, chartConfig)
      case 'stackedBar':
        return renderStackedBarChart(cData, chartConfig);
      case 'bar':
      default:
        return renderBarChart(cData, chartConfig);
    }
  };

  RenderChart.propTypes = {
    cData: PropTypes.any.isRequired,
    chartConfig: PropTypes.any.isRequired,
    chartKey: PropTypes.any.isRequired,
  };

  export default RenderChart
import React, { useState } from 'react';
import '../App.css';
import { useRecoilState } from 'recoil';
import dataState from '../atom/dataStateAtom';
import cDataState from '../atom/cDataStateAtom';
import styled from 'styled-components';

const CardContainer = styled.div`
  width: 80%;
  height: 80vh;
  margin: 0 auto;
  margin-top: 10%;
  border-radius: 10px;
  position: relative;
`;
const ProgressContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-top: 40px;
  position: relative;
`;

const Card = styled.div`
  padding: 20px;
  position: relative;
  width: 80%;
  height: 50%;
  margin-left: 10%;
  padding-top: 50px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledFileInput = styled.input.attrs({ type: 'file' })`
  margin-top: 40px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
  cursor: pointer;
  
  &:hover {
    background: #e6e6e6;
  }
`;

const StepCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? 'black' : '#ccc')};
  color: ${(props) => (props.active ? '#fff' : '#000')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${(props) => props.left};
  cursor: pointer;
  z-index: 999;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: black;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 99;
`;

const WizardPage = () => {
  const [data, setData] = useRecoilState(dataState);
  const [cData, setCData] = useRecoilState(cDataState);
  const [step, setStep] = useState(1);
  const [selectedTrends, setSelectedTrends] = useState([]);
  const [selectedGraphs, setSelectedGraphs] = useState([]);
  const trends = [
    'Artificial Intelligence',
    'Sustainability',
    'Remote Work',
    'Augmented Reality',
    'Climate Change',
    'Mental Health',
    'Cryptocurrency',
    'Electric Vehicles',
    'Renewable Energy',
    'Digital Transformation'
  ];
  const graphs = [
    'Artificial Intelligence',
    'Sustainability',
    'Remote Work',
    'Augmented Reality',
    'Climate Change',
    'Mental Health',
    'Cryptocurrency',
    'Electric Vehicles',
    'Renewable Energy',
    'Digital Transformation'
  ];
  const numberOfSteps = 3

  const handleToggleTrend = (trend) => {
    if (selectedTrends.includes(trend)) {
      setSelectedTrends(selectedTrends.filter((t) => t !== trend));
    } else {
      setSelectedTrends([...selectedTrends, trend]);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const jsonData = JSON.parse(event.target.result);
          setData(jsonData);
          setCData(jsonData)
          console.log(cData, data);
        } catch (error) {
          console.error('Error parsing JSON file:', error);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleToggleGraph = (graph) => {
    if (selectedGraphs.includes(graph)) {
      setSelectedGraphs(selectedGraphs.filter((t) => t !== graph));
    } else {
      setSelectedGraphs([...selectedGraphs, graph]);
    }
  };


  const handleNext = () => {
    if (step < numberOfSteps) {
      setStep(step + 1);
    } else {
      window.location.href = '/dashboard';
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleStepClick = (stepNumber) => {
    setStep(stepNumber);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Card>
            <h1>1. Importera data</h1>
            <br/>
            <br/>
            <StyledFileInput  onChange={handleFileChange} />
            <div className="button-container">
            <button className="custom-button" onClick={handleNext}>Nästa</button>
            </div>
          </Card>
        );
      case 2:
        return (
          <Card>
            <h1>2. Välj trend</h1>
            <br/>
            <div className="checkbox-button-container">
              {trends.map((trend) => (
                <button
                  key={trend}
                  className={`checkbox-button ${
                    selectedTrends.includes(trend) ? 'checked' : ''
                  }`}
                  onClick={() => handleToggleTrend(trend)}
                >
                  <div className="checkbox-marker">
                    {selectedTrends.includes(trend) ? '✔' : ''}
                    <span className="checkbox-text">{trend}</span>
                  </div>
                </button>
              ))}
            </div>
            <div className="button-container">
              <button className="custom-button" onClick={handlePrevious}>Föregående</button>
              <button className="custom-button" onClick={handleNext}>Nästa</button>
            </div>
          </Card>
        );
      case 3:
        return (
          <Card>
            <h1>3. Välj graf</h1>
            <br/>
            <div className="checkbox-button-container">
              {graphs.map((graph) => (
                <button
                  key={graph}
                  className={`checkbox-button ${
                    selectedGraphs.includes(graph) ? 'checked' : ''
                  }`}
                  onClick={() => handleToggleGraph(graph)}
                >
                  <div className="checkbox-marker">
                    {selectedGraphs.includes(graph) ? '✔' : ''}
                    <span className="checkbox-text">{graph}</span>
                  </div>
                </button>
              ))}
            </div>
            <div className="button-container">
            <button className="custom-button" onClick={handlePrevious}>Föregående</button>
            <button className="custom-button" onClick={handleNext}>Gå till dashboard</button>
            </div>
            
          </Card>
        );
      default:
        return null;
    }
  };
  const progress = (step - 1) * 100 / (numberOfSteps -1);

  return (
    <CardContainer>
      <ProgressContainer>
        <ProgressBar style={{ width: `${progress}%` }} />
        <StepCircle active={step >= 1} left="0%" onClick={() => handleStepClick(1)}>1</StepCircle>
        <StepCircle active={step >= 2} left="50%" onClick={() => handleStepClick(2)}>2</StepCircle>
        <StepCircle active={step >= 3} left="100%" onClick={() => handleStepClick(3)}>3</StepCircle>
      </ProgressContainer>
      {renderStep()}
    </CardContainer>
  );
};

export default WizardPage;

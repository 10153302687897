import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import '../App.css';
import Statistics from '../components/statistics';
import FilterSection from '../components/filterSection';
import ErrorPopup from '../components/errorPopup';
import filterNestedArrayByPatientID from '../utils/filterTrend';
import filterNestedArrayByCategory from '../utils/filterUser';
import filterNestedArrayByDate from '../utils/filterPeriod';
import RenderChart from '../components/renderChart';
import DashboardHeader from '../components/dashboardHeader';
import { useRecoilState } from 'recoil';
import dataState from '../atom/dataStateAtom';
import cDataState from '../atom/cDataStateAtom';
import startDateState from '../atom/startDateStateAtom';
import endDateState from '../atom/endDateStateAtom';
import Preloader from '../components/preloader';

const GraphPage = () => {
  const [data, setData] = useRecoilState(dataState);
  const [loading, setLoading] = useState(false);
  const [cData, setCData] = useRecoilState(cDataState);
  const [startDate, setStartDate] = useRecoilState(startDateState);
  const [endDate, setEndDate] = useRecoilState(endDateState);
  const [error, setError] = useState(null);
  const [filterKey, setFilterKey] = useState(0);

  const handleStartDateChange = date => {
    setStartDate(date);
    let filterdData = filterNestedArrayByDate(data[0].chartData, startDate, endDate)
    setCData(filterdData)
  };

  const handleEndDateChange = date => {
    setEndDate(date);
    let filterdData = filterNestedArrayByDate(data[0].chartData, startDate, endDate)
    setCData(filterdData)
  };

  const handleFileChange = (e) => {
    setLoading(true); 
    const file = e.target.files[0];
    e.target.value = null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const jsonData = JSON.parse(event.target.result);
          setData(jsonData);
          setCData(jsonData);
          setFilterKey(prevKey => prevKey + 1);
          setLoading(false);
        } catch (error) {
          setError(`Error parsing JSON: ${error.message}`);
          setLoading(false);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleUserFilterChange = (selectedValue) => {
    let filterdData = filterNestedArrayByCategory(data, selectedValue);
    setCData(filterdData)
  };
  const handleTrendFilterChange = (selectedValue) => {
    let filterdData = filterNestedArrayByPatientID(data, selectedValue);
    setCData(filterdData)
  };
  useEffect(() => {
    const handleError = (message, source, lineno, colno, error) => {
      const errorMessage = `${message} at ${source}:${lineno}:${colno}:${error}`;
      setError(errorMessage);
      return true; 
    };

    window.onerror = handleError;

    return () => {
      window.onerror = null; 
    };
  }, []);

  const closeErrorModal = () => {
    setError(null);
  };

  return (
    <div className="dashboard-container">
      <DashboardHeader
        handleFileChange={handleFileChange}
      />
      <Statistics />
      <FilterSection
        key={filterKey}
        startDate={startDate}
        endDate={endDate}
        data={data}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleUserFilterChange={handleUserFilterChange}
        handleTrendFilterChange={handleTrendFilterChange}
      />
       <div className='dashboard'>
      {loading ? (
        <Preloader />
      ) : (
        cData && cData.map((item, index) => (
          item.chartData && item.chartConfig ? (
            <RenderChart key={index} chartKey={index} cData={item.chartData} chartConfig={item.chartConfig} />
          ) : null
        ))
      )}
    </div>
      {error && <ErrorPopup errorMessage={error} onClose={closeErrorModal} />}
    </div>
  );
};

export default GraphPage;
